// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$my-palette: (
  50: #fff8e5,
  100: #ffebbd,
  200: #ffdf94,
  300: #ffd36e,
  400: #fec958,
  500: #ffd36e,
  600: #fbb345,
  700: #f9a241,
  800: #f7923d,
  900: #f47635,
  contrast: (50: #fff8e5,
    100: #ffebbd,
    200: #ffdf94,
    300: #ffd36e,
    400: #fec958,
    500: #ffd36e,
    600: #fbb345,
    700: #f9a241,
    800: #f7923d,
    900: #f47635,
  )
);

$theme-primary: mat.define-palette($my-palette);
$theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((color: (primary: $theme-primary,
        accent: $theme-accent,
        warn: $theme-warn,
      ),
      typography: mat.define-typography-config(),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: white;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: #FFD36E;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-unselected-focus-icon-color: white;
  --mdc-checkbox-unselected-icon-color: white;
  --mdc-checkbox-unselected-hover-icon-color: white;
}

button.mat-mdc-raised-button.mdc-button:disabled {
  --mdc-protected-button-disabled-label-text-color: #1D3669;
  --mdc-protected-button-disabled-container-color: #FFD36E;
  opacity: 0.5;
}

button.mat-mdc-fab.mdc-fab:disabled {
  --mdc-fab-container-color: #FFD36E;
  --mdc-fab-icon-color: #1D3669;
  --mat-mdc-fab-color: #1D3669;
  opacity: 0.5;

}