.mat-mdc-radio-button.mat-mdc-radio-checked {
    border-color: #FFD36E;
}

.radio-tick {
    height: 22px;
    width: 22px;
    border: 1px solid #8E99A8;
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &.checked {
        border: unset;
        background-image: url("/assets/icons/check_circle.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }
}