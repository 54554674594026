.nav-link {
    position: relative;

    &.active::before {
        opacity: 1;
        width: 100%;
    }

    &::before {
        content: "";
        height: 4px;
        width: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        background-color: #FFD36E;
        opacity: 0;
        transition: all .3s;
    }
}