.raised-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 12px 5px 12px;
    border-radius: 4px;
    background-color: #FFD36E !important;
    color: #1D3669;
    font-weight: 700;
    font-size: 12px;
    white-space: nowrap;

    @media (min-width: 640px) {
        padding: 5px 12px 7px 12px;
        border-radius: 6px;
        font-size: 14px;
    }

    &:disabled {
        opacity: 0.5;
    }
}

.raised-button-lg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    border-radius: 6px;
    background-color: #FFD36E !important;
    color: #1D3669;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;

    @media (min-width: 640px) {
        padding: 12px 24px;
        font-size: 16px;
        line-height: 24px;
    }

    &:disabled {
        opacity: 0.5;
    }
}

button {
    &.mdc-button {
        position: relative;
        z-index: 1;
    }

    &.mat-mdc-outlined-button {
        height: unset !important;
    }

    .mdc-button__label {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        @media (min-width: 640px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

button.mat-mdc-raised-button.mdc-button {
    padding: 10px 20px;
    border-radius: 6px;
    height: unset;

    @media (min-width: 640px) {
        padding: 12px 20px;
    }

    .mdc-button__label {
        color: #1D3669;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        @media (min-width: 640px) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}