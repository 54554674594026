
mat-stepper.mat-stepper-horizontal {
    background-color: transparent;
    
    .mat-horizontal-stepper-header-container {
        justify-content: center;
    }

    .mat-horizontal-content-container {
        padding: 0 24px 32px 24px;
    }

    .mat-step-icon {
        height: 32px;
        width: 32px;
        background: linear-gradient(113deg, #EAEAEA 7.05%, #838383 64.94%);
        position: relative;
        z-index: 1;

        &.mat-step-icon-selected,
        &.mat-step-icon-state-done {
            background: linear-gradient(113deg, #F2D374 7.05%, #B88837 64.94%);
        }

        &.mat-step-icon-selected::after {
            content: "";
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            position: relative;
            display: block;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            box-shadow: 0px 0px 0px 2px #B88837;
        }

        .mat-step-icon-content {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;

            .mat-icon {
                font-size: 18px;
                height: 18px;
                width: 18px;
            }
        }
    }

    .mat-stepper-horizontal-line {
        display: none;
    }

    .mat-step-header::after,
    .mat-step-header::before {
        border: unset !important;
        height: 2px !important;
        right: -2px !important;
        width: calc(50% - 14px) !important;
    }

    .mat-step-header {
        width: 200px;
        padding: 24px 0px;

        &[ng-reflect-state="done"]::after {
            background: linear-gradient(113deg, #F2D374 7.05%, #B88837 64.94%);
        }

        &[aria-selected="true"] {
            &::before {
                width: calc(50% - 20px) !important;
                background: linear-gradient(113deg, #F2D374 7.05%, #B88837 64.94%);
            }

            &::after {
                background: linear-gradient(113deg, #F2D374 7.05%, #B88837 64.94%);
                width: calc(50% - 20px) !important;
            }

            .mat-step-text-label {
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                color: #FFF;

                @media (min-width: 640px) {
                    font-size: 16px;
                    line-height: 24px;

                }
            }
        }

        &[aria-selected="false"] {
            &[ng-reflect-state="done"]::before {
                background: linear-gradient(113deg, #F2D374 7.05%, #B88837 64.94%);
            }
            &[ng-reflect-state="number"]::after {
                background: linear-gradient(113deg, #EAEAEA 7.05%, #838383 64.94%);
            }

            .mat-step-text-label {
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                color: #C5C5C5;

                @media (min-width: 640px) {
                    font-size: 16px;
                    line-height: 24px;

                }
            }
        }

        .mat-step-text-label {
            white-space: wrap;
        }
    }

    .mat-step-header[ng-reflect-state="number"][aria-selected="false"]::before {
        background: linear-gradient(113deg, #EAEAEA 7.05%, #838383 64.94%);
    }


}