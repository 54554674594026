@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

.swiper-slide {
    width: -moz-fit-content;
    width: fit-content;
}

.swiper-pagination {
    position: relative;
    margin-top: 26px;
    bottom: unset !important;

    @media (max-width: 768px) {
        margin-top: 10px;
    }
}

.swiper-pagination-bullet {
    width: 36px;
    height: 5px;
    background-color: #0081B7;
    border-radius: 8px;
    opacity: 0.8;

    &.swiper-pagination-bullet-active {
        background: #FFD36E;
        opacity: 0.6;
    }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 0;
}

.swiper-button-prev:after, .swiper-button-next:after {
    color: #5A6370;
    font-size: 24px !important;
    font-weight: 900;
 }

 .swiper-button-next::before {
    content: "";
    background: linear-gradient(270deg, #1D3669 0%, rgba(29, 54, 105, 0) 100%);
    position: absolute;
    right: -11px;
    top: 0;
    z-index: -1;
    width: 40px;
    height: 100%;
 }

 .swiper-button-prev::before {
    content: "";
    background: linear-gradient(90deg, #1D3669 0%, rgba(29, 54, 105, 0) 100%);
    position: absolute;
    left: -10px;
    top: 0;
    z-index: -1;
    width: 40px;
    height: 100%;
 }