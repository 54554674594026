@import "/src/styles/select";
@import "/src/styles/button";
@import "/src/styles/swiper";
@import "/src/styles/spinner";
@import "/src/styles/nav";
@import "/src/styles/modal";
@import "/src/styles/expansion-panel";
@import "/src/styles/radio";
@import "/src/styles/stepper";
@import "/src/styles/ckeditor";
@import "/src/styles/quill";

html,
body {
    height: 100%;
    width: 100%;
}

body,
.overflow-y-auto {

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 2px;
    }
}

ul>li::marker {
    font-size: 10px;
}

// handle close sidebar
.sidebar-pushcontent.menu-close .mobile-sidebar {
    right: -300px;
}

.cdk-visually-hidden {
    visibility: hidden;
}

.modal-dialog.sw-custom-payment-vlinkpay {
    position: relative;
    max-width: 404px;
    margin: 32px auto;
}

.modal-dialog.sw-custom-payment-vlinkpay .btn-close {
    position: absolute;
    right: 1rem;
    top: 1rem
}

.underline {
    text-decoration: underline;
}

.mt-10 {
    margin-top: 40px;
}

#header {
    transition: background 1s ease;
    background: #1D3669;

    &.header-dark {
        border-bottom: 1px solid rgba(169, 172, 177, 0.24);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.06) 100%), rgba(29, 54, 105, 0.90);
        backdrop-filter: blur(10px);
    }
}

.swal2-popup.sw-custome__popup {
    color: white;
    background: #1D3669;
    display: grid;
    border: 1px solid #FFD36E;
    border-radius: 8px;

    .sw-custome__title {
        color: #FFD36E;
    }

    .sw-custome__actions .sw-custome__confirm-button {
        background-color: #FFD36E;
        color: #1D3669;
        box-shadow: unset;
        width: 80px;
    }
}

img {
    display: unset !important;
}