.mat-accordion {

    .mat-expansion-panel {
        background: transparent;
        box-shadow: unset;
        color: #FFF;

        border-radius: 4px !important;
        border: 1px solid rgba(169, 172, 177, 0.48);
        background: rgba(169, 172, 177, 0.16);
        margin-bottom: 16px;

        &.mat-expanded {
            border-color: #FFD36E;
            background: transparent;
        }

        .mat-expansion-panel-header {
            padding: 8px 12px;
            height: 60px;
        }

        .mat-expansion-panel-body {
            padding: 0 16px 16px;
        }
    }
}