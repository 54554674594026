.custom-select {
    position: relative;

    &.select-transparent {
        .select-selected {
            background-color: transparent;

            &:after {
                top: 17px;
                right: 14px;
                width: 0;
                height: 0;
                border: 4px solid transparent;
                border-color: #fff transparent transparent transparent;
                transform: unset;
            }

            &.select-arrow-active {
                &:after {
                    transform: rotate(180deg) translateY(-50%);
                    top: 16px;
                }
            }
        }

        .select-items {
            top: 100%;
        }
    }

    &.select-border {

        .select-selected {
            padding: 2px 6px 2px 11px;
            border: 1px solid #A9ACB152;

            &::after {
                top: 14px;
                right: 12px;
            }

            &.select-arrow-active:after {
                top: 12px;
            }
        }

        .select-items {
            top: calc(100% + 8px);
        }
    }

    select {
        display: none;
    }

    .select-selected {
        background-color: #A9ACB114;
        border-radius: 4px;

        &:after {
            position: absolute;
            content: "";

            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            transform: rotate(45deg) translateY(-50%);
            width: 7px;
            height: 7px;
            top: 18px;
            right: 20px;
            transition: all .4s ease-in-out;
            transform-origin: 50% 0;
        }

        &.select-arrow-active {
            &:after {
                transform: rotate(-135deg) translateY(-50%);
                top: 20px;
            }
        }
    }

    .select-items {
        position: absolute;
        background-color: #435177;
        top: calc(100% + 8px);
        left: 0;
        right: 0;
        z-index: 99;
        padding: 8px 0px;
        border-radius: 4px;
        border: 1px solid #747e93;

        transition: all .4s ease-in-out;
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transform: translateY(0);

        &.select-hide {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transform: translateY(-15px);
        }

        div {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;

            &.same-as-selected {
                color: #FFD36E;
                font-weight: 700;
            }

            &:hover {
                background-color: #5c6784;
            }
        }

    }

    .select-items div,
    .select-selected {
        color: #fff;
        padding: 8px 16px;
        cursor: pointer;
        user-select: none;
        position: relative;
        z-index: 1;
    }
}